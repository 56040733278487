import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { Box } from '@chakra-ui/react';

import scrollTo from 'timelesstime-ui/utils/scroll-to';
import ToolRegistration from '../tool-registration';
import QuestionsInput from './questionsInput';
import Results from './results';
import questions from './questions';
import ArtificialLoading from '../loading';

const GACategory = 'tool_hr-contribution-assessor';

const defaultAnswers = questions.reduce(
  (carry, questionGroup) =>
    questionGroup.questions.reduce(
      (questionCarry, question) => ({ ...questionCarry, [question.id]: 3 }),
      carry,
    ),
  {},
);

const HRContributionAssessor = () => {
  const toolWrapperRef = useRef();

  const [answers, setAnswers] = useState(defaultAnswers);
  const [showResults, setShowResults] = useState(false);

  const scrollToToolTop = scrollTo(toolWrapperRef);

  const toggleShowResults = (show) => {
    setShowResults(show);
    setTimeout(() => scrollToToolTop(), 0);
    ReactGA.event({
      category: GACategory,
      action: show ? 'show results' : 'show questions',
    });
  };

  if (showResults) {
    const results = questions.map((questionGroup) => {
      const {
        title,
        ideal,
        questions: groupQuestions,
        resultsText,
      } = questionGroup;
      const actual = groupQuestions.reduce((carry, question) => {
        const questionValue = answers[question.id] || 3;
        const answerValue = question.answerWeightings[questionValue] || 0;
        return (carry * 10 + answerValue * 10) / 10;
      }, 0);
      const resultsTextIndex = Math.floor(
        (resultsText.length - 1) * (actual / ideal),
      );
      return {
        title,
        ideal,
        actual,
        text: resultsText[resultsTextIndex],
      };
    });

    return (
      <Box ref={toolWrapperRef}>
        <ArtificialLoading
          onLoaded={() => setTimeout(() => scrollToToolTop(), 0)}
        >
          <Results
            results={results}
            onShowQuestions={() => toggleShowResults(false)}
          />
        </ArtificialLoading>
      </Box>
    );
  }

  return (
    <Box ref={toolWrapperRef}>
      <ToolRegistration>
        <QuestionsInput
          questions={questions}
          answers={answers}
          onChange={(questionId, value) => {
            setAnswers({
              ...answers,
              [questionId]: value,
            });
          }}
          onReset={() => {
            setShowResults(false);
            setAnswers(defaultAnswers);
            setTimeout(() => scrollToToolTop(), 0);
            ReactGA.event({
              category: GACategory,
              action: 'reset',
            });
          }}
          onSubmit={() => toggleShowResults(true)}
        />
      </ToolRegistration>
    </Box>
  );
};

export default HRContributionAssessor;
