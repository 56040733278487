import React, { Fragment } from 'react';
import {
  Flex,
  Box,
  Button,
  useToken,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
} from '@chakra-ui/react';
import { Radar } from 'recharts/es6/polar/Radar';
import { RadarChart } from 'recharts/es6/chart/RadarChart';
import { PolarGrid } from 'recharts/es6/polar/PolarGrid';
import { PolarAngleAxis } from 'recharts/es6/polar/PolarAngleAxis';
import { PolarRadiusAxis } from 'recharts/es6/polar/PolarRadiusAxis';
import { ResponsiveContainer } from 'recharts/es6/component/ResponsiveContainer';
import { Tooltip } from 'recharts/es6/component/Tooltip';
import { Text } from 'recharts/es6/component/Text';
import Heading from 'timelesstime-ui/components/heading';

const Results = ({ results, onShowQuestions }) => {
  const darkBlue = useToken('colors', 'tt.darkBlue');
  const lightBlue = useToken('colors', 'tt.lightBlue');
  const yellow = useToken('colors', 'yellow.500');
  const gray100 = useToken('colors', 'gray.100');
  const gray400 = useToken('colors', 'gray.400');
  const gray800 = useToken('colors', 'gray.800');
  return (
    <Box>
      <Flex justify="flex-end" mb={6}>
        <Button onClick={onShowQuestions} colorScheme="orange" size="sm">
          Go back
        </Button>
      </Flex>
      <Stack direction={['column', 'column', 'row']}>
        <Flex justify="center" flexGrow="1">
          <Box mt={[0, 0, 6]} width={410} height={350}>
            <ResponsiveContainer width={410} height={350}>
              <RadarChart
                cx={200}
                cy={175}
                width={410}
                height={350}
                data={results}
              >
                <PolarGrid fill={darkBlue} stroke={gray400} />
                <PolarAngleAxis
                  dataKey="title"
                  fontSize="0.75rem"
                  fontWeight="bold"
                  tickLine={false}
                  style={{ color: gray800 }}
                  tick={({ x, y, payload, ...props }) => (
                    <g className="recharts-layer recharts-polar-angle-axis-tick">
                      <text
                        {...props}
                        x={x}
                        y={y}
                        className="recharts-text recharts-polar-angle-axis-tick-value"
                        style={{ color: gray800 }}
                      >
                        {payload.value.split(' ').map((text, i) => (
                          <tspan key={text} x={x} dy={i === 0 ? 0 : 15}>
                            {text}
                          </tspan>
                        ))}
                      </text>
                    </g>
                  )}
                />
                <PolarRadiusAxis
                  angle={60}
                  domain={[0, 8]}
                  axisLine={false}
                  tick={(tickData) => {
                    if (tickData.payload.value === 0) {
                      return undefined;
                    }
                    if (tickData.payload.value > 6) {
                      return undefined;
                    }
                    return (
                      <Text
                        {...tickData}
                        fontSize="0.7rem"
                        className="recharts-polar-angle-axis-tick-value"
                      >
                        {tickData.payload.value}
                      </Text>
                    );
                  }}
                />
                <Radar
                  name="An ideal Firm"
                  dataKey="ideal"
                  stroke={yellow}
                  fill={darkBlue}
                  fillOpacity={0.6}
                />
                <Radar
                  name="Your Firm"
                  dataKey="actual"
                  stroke={yellow}
                  fill={lightBlue}
                  fillOpacity={0.6}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: gray100,
                    border: `1px solid ${gray800}`,
                    padding: '4px 5px',
                  }}
                  labelStyle={{
                    color: gray800,
                    fontSize: '0.7rem',
                    fontWeight: 'bold',
                  }}
                  itemStyle={{ color: gray800, fontSize: '0.7rem' }}
                />
              </RadarChart>
            </ResponsiveContainer>
          </Box>
        </Flex>
        <Flex justify="center" pt={[4, 4, 0]}>
          <Table size="sm" variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>Area</Th>
                <Th>Your Firm</Th>
                <Th>Ideal Firm</Th>
              </Tr>
            </Thead>
            <Tbody>
              {results.map(({ title, ideal, actual }) => (
                <Tr key={title}>
                  <Td>{title}</Td>
                  <Td>{ideal}</Td>
                  <Td>{actual}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Stack>
      <Box mt={8}>
        {results.map((answer) => (
          <Fragment key={answer.title}>
            <Heading as="h2">{answer.title}</Heading>
            {answer.text.map((text) => (
              <Text key={text}>{text}</Text>
            ))}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default Results;
