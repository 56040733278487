import React, { Fragment } from 'react';
import { Box, Flex, Text, Button, VStack } from '@chakra-ui/react';
import Slider from 'timelesstime-ui/components/slider';
import Heading from 'timelesstime-ui/components/heading';

const Results = ({ questions, answers, onChange, onReset, onSubmit }) => (
  <>
    <VStack spacing={6} alignItems="stretch">
      {questions.map((questionGroup) => (
        <Box key={questionGroup.title}>
          <Heading as="h5" mb={4}>
            {questionGroup.title}
          </Heading>
          <VStack spacing={4} alignItems="stretch">
            {questionGroup.questions.map((question) => (
              <VStack key={question.id} spacing={2} alignItems="stretch">
                <Text style={{ fontWeight: 'bold', fontSize: '0.85rem' }}>
                  {question.text}
                </Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  value={answers[question.id]}
                  marks={{
                    1: { label: 'Strongly disagree' },
                    2: { label: 'Disagree' },
                    3: { label: 'Neither agree nor disagree' },
                    4: { label: 'Agree' },
                    5: { label: 'Strongly Agree' },
                  }}
                  onChange={(value) => onChange(question.id, value)}
                  handleStyle={{ cursor: 'grab' }}
                />
              </VStack>
            ))}
          </VStack>
        </Box>
      ))}
    </VStack>
    <Flex justify="flex-end" mt={8}>
      <Button
        size="sm"
        colorScheme="orange"
        onClick={onReset}
        aria-label="clear answers"
      >
        Reset
      </Button>
    </Flex>
    <Flex justify="center" mt={4}>
      <Button onClick={onSubmit} colorScheme="orange">
        Get my results
      </Button>
    </Flex>
  </>
);

export default Results;
