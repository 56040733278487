import React from 'react';
import axios from 'redaxios';
import ReactGA from 'react-ga';
import { Container } from '@chakra-ui/react';

import TrackingContext from 'timelesstime-ui/contexts/tracking';
import CaptureDetailsForm from 'timelesstime-ui/components/capture-details-form';

const GACategory = 'tool-registration';

const toolRegistrationFields = [
  {
    name: 'firstName',
    label: 'First Name',
    required: true,
    placeholder: 'Joe/Jane',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'First name must be provided',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    required: true,
    placeholder: 'Bloggs',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'Last name must be provided',
  },
  {
    name: 'email',
    label: 'Email',
    required: true,
    placeholder: 'me@bloggs.com',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'Email must be provided',
  },
  {
    name: 'company',
    label: 'Company',
    required: false,
    placeholder: 'Bloggs Ltd.',
  },
  {
    name: 'jobTitle',
    label: 'Job Title',
    required: false,
    placeholder: 'Manager',
  },
];

const handleSubmit = async (formData) => {
  try {
    await axios.post('/tool-registration', {
      data: {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        title: formData.title,
        company: formData.company,
        trigger: 'tool registration',
      },
    });
    ReactGA.event({
      category: GACategory,
      action: 'submitted',
    });
    return 'Thank you, your details have been submitted';
  } catch (error) {
    ReactGA.exception({
      error: `Errror submitting tool registration form - ${error.message}`,
    });
    throw new Error(
      'There was an error signing you up. Please try again later or email info@timelesstime.co.uk',
    );
  }
};

const ToolRegistrationForm = ({ onAfterSubmit, ...props }) => (
  <TrackingContext.Consumer>
    {(tracking) => (
      <Container
        maxW="container.md"
        background="gray.50"
        mt={6}
        px={4}
        py={4}
        border="1px solid"
        borderColor="gray.100"
        borderRadius="sm"
      >
        <CaptureDetailsForm
          fields={toolRegistrationFields}
          handleSubmit={async (formData) => {
            const submitText = await handleSubmit(formData);
            tracking.associate(
              formData.email,
              formData.firstName,
              formData.lastName,
            );
            if (typeof onAfterSubmit === 'function') {
              onAfterSubmit();
            }
            return submitText;
          }}
          {...props}
        />
      </Container>
    )}
  </TrackingContext.Consumer>
);

export default ToolRegistrationForm;
