import React from 'react';
import { Text } from '@chakra-ui/react';

import TrackingContext from 'timelesstime-ui/contexts/tracking';
import ToolRegistrationForm from './tool-registration-form';

const ToolRegistration = ({ children, ...props }) => (
  <TrackingContext.Consumer>
    {(tracking) => {
      if (!tracking.isAnonymous || typeof window === 'undefined') {
        return <>{children}</>;
      }
      return (
        <>
          <Text mb={4}>
            To use our free tool, we&apos;d like to ask something of you. Let us
            send you our free newsletter full of Management & HR news and
            comment. We won&apos;t share your details with anyone else and
            there&apos;ll be no spam. Promise.
          </Text>
          <ToolRegistrationForm {...props} />
        </>
      );
    }}
  </TrackingContext.Consumer>
);

export default ToolRegistration;
