import React from 'react';
import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import { Alert, AlertDescription } from '@chakra-ui/react';

const ToolNoScriptWarning = (props) => (
  <noscript>
    <Alert status="error" {...props}>
      <AlertDescription>
        Our tools require JavaScript to be enabled, unfortunately this is
        disabled in your browser. To use our free tools, please enable
        JavaScript.{' '}
        <CanonicalLink to="https://www.google.com/search?q=How+do+i+enable+JavaScript%3f">
          Find out how
        </CanonicalLink>
        .
      </AlertDescription>
    </Alert>
  </noscript>
);

export default ToolNoScriptWarning;
