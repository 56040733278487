import React from 'react';
import { graphql } from 'gatsby';
import {
  Container,
  Box,
  Divider,
  Text,
  Flex,
  Tooltip,
  Badge,
} from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { ToolLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import Html from 'timelesstime-ui/components/html';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import Heading from 'timelesstime-ui/components/heading';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import PageLayout from '../components/layout';
import ToolFactory from '../components/tools';
import ToolStructuredData from '../components/structured-data/tool';
import ToolNoScriptWarning from '../components/tool-noscript-warning';

const ToolPage = ({ data: { tool } }) => {
  const ToolComponent = ToolFactory(tool.componentName);
  return (
    <PageLayout
      leadInHeader={
        <ToolLeadInHeader
          heading={tool.heading || tool.title}
          crumbs={[
            {
              url: `/${tool.slug}/`,
              title: tool.title,
            },
          ]}
        />
      }
    >
      <SEO
        title={tool.title}
        keywords={tool.metaKeywords}
        description={tool.metaDescription}
        canonicalPath={tool.fields.path}
      />
      <ToolStructuredData
        slug={tool.slug}
        title={tool.title}
        description={tool.metaDescription}
        plaintextContent={tool.fields.plaintextContent}
        caseStudies={tool.caseStudies || []}
        isFree={!!tool.isFree}
        createdAt={new Date(tool.createdAt)}
        updatedAt={new Date(tool.updatedAt)}
      />

      <Container maxW="container.lg" mb={12}>
        <Box mt={4}>
          <CanonicalLink color="orange.500" to="/tools/">
            view all tools
          </CanonicalLink>
        </Box>

        <Box as="header">
          <Heading as="h1" mb={2}>
            {tool.title}
          </Heading>
        </Box>

        {(tool.isFree || (!tool.isFree && tool.credits)) && (
          <Box mb={6}>
            <Flex mt={3} mb={3}>
              {tool.isFree && (
                <Tooltip
                  aria-label="Free tool"
                  label="This tool is free to use"
                >
                  <Badge colorScheme="green" variant="solid">
                    Free
                  </Badge>
                </Tooltip>
              )}
              {!tool.isFree && tool.credits && (
                <Tooltip
                  aria-label="Premium tool"
                  label="This is a premium tool"
                >
                  <Badge colorScheme="blue" variant="solid">
                    {tool.credits} credits
                  </Badge>
                </Tooltip>
              )}
            </Flex>
            <Divider />
          </Box>
        )}

        <Html source={tool.fields.overviewHtml} headerLevelStart={2} />

        {tool.fields.instructionsHtml && (
          <>
            <Heading as="h2">How to use this tool</Heading>
            <Html source={tool.fields.instructionsHtml} headerLevelStart={3} />
          </>
        )}

        {ToolComponent && (
          <>
            <Divider my={12} />

            <ToolNoScriptWarning my={8} />

            <Box>{ToolComponent}</Box>
          </>
        )}
      </Container>

      <Jumbotron as="footer" mt={8} p>
        <Container maxW="container.lg" my={4}>
          <Text pb={4}>
            Using the tool isn&apos;t the whole story - put in place some
            changes to get the most out of your people.
          </Text>
          <CallMeBack callBackText="We can help you. Get in touch now" />
        </Container>
      </Jumbotron>
    </PageLayout>
  );
};

export const query = graphql`
  query ToolSinglePageBySlug($slug: String!) {
    tool: contentfulTool(slug: { eq: $slug }) {
      id
      title
      slug
      metaDescription
      createdAt
      updatedAt
      title
      isFree
      credits
      componentName
      fields {
        path
        url
        writtenAt
        isNew
        plaintextContent
        overviewHtml
        instructionsHtml
      }
    }
  }
`;

export default ToolPage;
