import React, { useState, useEffect } from 'react';

import LoadingHeadSpinner from 'timelesstime-ui/components/loading-head-spinner';

const LoadingSimulator = ({ children, onLoaded }) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const randomLoadingTime = Math.random() * 30 + 500;
    setTimeout(() => {
      setLoading(false);
      onLoaded();
    }, randomLoadingTime);
  }, []);
  if (isLoading) {
    return <LoadingHeadSpinner text="crunching the numbers" />;
  }
  return <>{children}</>;
};

export default LoadingSimulator;
