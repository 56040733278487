export default [
  {
    title: 'Recruitment',
    ideal: 7.1,
    questions: [
      {
        id: 'recruitment_1',
        text:
          'Your employees exploit extensive knowledge (of products, services and customers) in doing their jobs.',
        answerWeightings: {
          5: 2.3,
          4: 1.3,
          3: 0.8,
          2: 0.3,
          1: 0,
        },
      },
      {
        id: 'recruitment_2',
        text:
          'You ensure that you hire the very best people with ready-to-use skills for each job you recruit.',
        answerWeightings: {
          5: 1.5,
          4: 1,
          3: 0.5,
          2: 0.2,
          1: 0,
        },
      },
      {
        id: 'recruitment_3',
        text:
          'You have a very cohesive and consistent set of HR practices across your organisation and these are applied equitably to all employees.',
        answerWeightings: {
          5: 1.4,
          4: 0.9,
          3: 0.5,
          2: 0.2,
          1: 0,
        },
      },
      {
        id: 'recruitment_4',
        text:
          'Your employee relations are excellent with staff and managers working in partnership rather than in hierarchy.',
        answerWeightings: {
          5: 1.2,
          4: 0.7,
          3: 0.3,
          2: 0.1,
          1: 0,
        },
      },
      {
        id: 'recruitment_5',
        text:
          'You balance the use of permanent staff with contract workers to be able to quickly reduce staff costs when workload reduces.',
        answerWeightings: {
          5: 0.7,
          4: 0.5,
          3: 0.2,
          2: 0.1,
          1: 0,
        },
      },
    ],
    resultsText: [
      [
        'The web analysis shows that your approach to recruitment and people management is successful. Your use of contract workers and knowledge workers appears to be effective.',
        'High knowledge and competencies in staff builds competitive advantage. Staff and managers working in partnership increases employee commitment. Focused HR practices, specifically in recruitment, optimises staff productivity.',
      ],
      [
        'You appear to manage staff knowledge to benefit your firm. Your recruitment practices and your employee relations appear to be reasonably sound.',
        'High knowledge and competencies in staff builds competitive advantage. Staff and managers working in partnership increases employee commitment. Focused HR practices, specifically in recruitment, optimises staff productivity.',
      ],
      [
        'Your recruitment practices and people management are fair, but you could be more focussed. You could make better use of contract staff to support workload issues.',
        'High knowledge and competencies in staff builds competitive advantage. Staff and managers working in partnership increases employee commitment. Focused HR practices, specifically in recruitment, optimises staff productivity.',
      ],
      [
        'This score suggests your people management is not as effective as it could be. You need to consider how to implement changes which will allow you to better manage your resources.',
        'High knowledge and competencies in staff builds competitive advantage. Staff and managers working in partnership increases employee commitment. Focused HR practices, specifically in recruitment, optimises staff productivity.',
      ],
      [
        'The results suggest that you have a lot of work to do in order to effectively recruit and manage staff. If you employ contract staff you could make more effective use of this resource.',
        'High knowledge and competencies in staff builds competitive advantage. Staff and managers working in partnership increases employee commitment. Focused HR practices, specifically in recruitment, optimises staff productivity.',
      ],
    ],
  },
  {
    title: 'Management & Leadership',
    ideal: 4.7,
    questions: [
      {
        id: 'management+leadership_1',
        text:
          'You have an absolute lack of hierarchy, management offer clear leadership, staff and managers give feedback to one another and there is visible teamwork throughout.',
        answerWeightings: {
          5: 3.7,
          4: 2.2,
          3: 1.3,
          2: 0.5,
          1: 0,
        },
      },
      {
        id: 'management+leadership_2',
        text:
          'Your staff and management are completely focussed on satisfying customers.',
        answerWeightings: {
          5: 1,
          4: 0.7,
          3: 0.4,
          2: 0.1,
          1: 0,
        },
      },
    ],
    resultsText: [
      [
        'Your score suggests that you have a flat structure, with managers and staff fully understanding their part in your business objectives. The organisation is optimally customer focussed.',
        'Managers lead staff by influencing staff day to day. Clear and effective leadership improves staff productivity. Customer focus improves employee motivation through enhanced meaningfulness.',
      ],
      [
        'Team work appears to be well focussed, and your managers offer satisfactory leadership. The organisation is reasonably customer-focused.',
        'Managers lead staff by influencing staff day to day. Clear and effective leadership improves staff productivity. Customer focus improves employee motivation through enhanced meaningfulness.',
      ],
      [
        'Whilst your staff are focussed there is room for improvement. You may need to consider the effectiveness of organisational communication.',
        'Managers lead staff by influencing staff day to day. Clear and effective leadership improves staff productivity. Customer focus improves employee motivation through enhanced meaningfulness.',
      ],
      [
        'Your customer-focus could be improved. You need to develop more teamwork and better leadership within the organisation.',
        'Managers lead staff by influencing staff day to day. Clear and effective leadership improves staff productivity. Customer focus improves employee motivation through enhanced meaningfulness.',
      ],
      [
        'There appears to be a lack of clarity in leadership. The results suggest too many levels in your hierarchy, and a lack of teamwork.',
        'Managers lead staff by influencing staff day to day. Clear and effective leadership improves staff productivity. Customer focus improves employee motivation through enhanced meaningfulness.',
      ],
    ],
  },
  {
    title: 'Pay & Benefits',
    ideal: 3.7,
    questions: [
      {
        id: 'pay+benefits_1',
        text:
          'You give employees the ability to earn stock or virtual stock in the firm and hence earn a share of the profits.',
        answerWeightings: {
          5: 1.7,
          4: 1.3,
          3: 0.8,
          2: 0.3,
          1: 0,
        },
      },
      {
        id: 'pay+benefits_2',
        text:
          'You have a clear incentive scheme where employees earn as they meet their objectives.',
        answerWeightings: {
          5: 0.9,
          4: 0.6,
          3: 0.4,
          2: 0.2,
          1: 0,
        },
      },
      {
        id: 'pay+benefits_3',
        text:
          'Your benefits (such as pensions, sick pay, private medical insurance and discount on purchases) are the best in the employment market.',
        answerWeightings: {
          5: 0.6,
          4: 0.4,
          3: 0.2,
          2: 0.1,
          1: 0,
        },
      },
      {
        id: 'pay+benefits_4',
        text:
          'Your pay and salaries for employees are in the top 10% of your industry segment.',
        answerWeightings: {
          5: 0.5,
          4: 0.3,
          3: 0.2,
          2: 0.1,
          1: 0,
        },
      },
    ],
    resultsText: [
      [
        'Your staff are stakeholders in the business and are generally highly committed.',
        'Enabling staff to benefit from the success of the firm enhances employee commitment. This must be done with care. Tailored staff benefits are benefits for both the company end employee, improving retention and lowering absence.',
      ],
      [
        'Your pay and benefits could be further honed to provide an ideal package.',
        'Enabling staff to benefit from the success of the firm enhances employee commitment. This must be done with care. Tailored staff benefits are benefits for both the company end employee, improving retention and lowering absence.',
      ],
      [
        'Your benefits and incentives are adequate, but far from ideal.',
        'Enabling staff to benefit from the success of the firm enhances employee commitment. This must be done with care. Tailored staff benefits are benefits for both the company end employee, improving retention and lowering absence.',
      ],
      [
        'Pay and benefits could be better used to drive your business forward.',
        'Enabling staff to benefit from the success of the firm enhances employee commitment. This must be done with care. Tailored staff benefits are benefits for both the company end employee, improving retention and lowering absence.',
      ],
      [
        'To be more effective your pay and benefits package should be reviewed.',
        'Enabling staff to benefit from the success of the firm enhances employee commitment. This must be done with care. Tailored staff benefits are benefits for both the company end employee, improving retention and lowering absence.',
      ],
    ],
  },
  {
    title: 'Staff Development',
    ideal: 3.2,
    questions: [
      {
        id: 'staff-development_1',
        text:
          'You share a significant amount of business information on your firm plans and performance with employees and encourage feedback and opinion.',
        answerWeightings: {
          5: 1.5,
          4: 1.1,
          3: 0.7,
          2: 0.3,
          1: 0,
        },
      },
      {
        id: 'staff-development_2',
        text:
          'You allow your employees to direct their own personal development and encourage them to do work that builds their own value in the employment market.',
        answerWeightings: {
          5: 1,
          4: 0.7,
          3: 0.5,
          2: 0.2,
          1: 0,
        },
      },
      {
        id: 'staff-development_3',
        text:
          'You solicit employee feedback, consider and evaluate it and act on it.',
        answerWeightings: {
          5: 0.7,
          4: 0.5,
          3: 0.3,
          2: 0.1,
          1: 0,
        },
      },
    ],
    resultsText: [
      [
        'Your organisation readily shares business information with staff. Staff feedback is very important, is valued, and is forthcoming. There is buy-in to personal development.',
        'Developing employees enhances their commitment and grows their skills. Increased skill enhances productivity. Company-wide improvement enables management to plan change. And soliciting and acting on feedback from staff enhances staff morale, reducing intention to quit.',
      ],
      [
        'Business information is shared. Staff have the opportunity to provide feedback. The analysis suggests some staff buy-in to self development.',
        'Developing employees enhances their commitment and grows their skills. Increased skill enhances productivity. Company-wide improvement enables management to plan change. And soliciting and acting on feedback from staff enhances staff morale, reducing intention to quit.',
      ],
      [
        'Some sharing of business information occurs. Staff provide limited and measured feedback. Buy-in to training is an issue.',
        'Developing employees enhances their commitment and grows their skills. Increased skill enhances productivity. Company-wide improvement enables management to plan change. And soliciting and acting on feedback from staff enhances staff morale, reducing intention to quit.',
      ],
      [
        "Limited business information is shared with staff. Staff feel uncomfortable about giving feedback, or don't have the opportunity. Staff training is an issue.",
        'Developing employees enhances their commitment and grows their skills. Increased skill enhances productivity. Company-wide improvement enables management to plan change. And soliciting and acting on feedback from staff enhances staff morale, reducing intention to quit.',
      ],
      [
        'Business information does not seem to be shared with staff. There no employee feedback. Staff have no ownership of their own development.',
        'Developing employees enhances their commitment and grows their skills. Increased skill enhances productivity. Company-wide improvement enables management to plan change. And soliciting and acting on feedback from staff enhances staff morale, reducing intention to quit.',
      ],
    ],
  },
  {
    title: 'Focussed Retention',
    ideal: 7.5,
    questions: [
      {
        id: 'focussed-retention_1',
        text:
          'You reward those employees who perform well and demonstrate the attitudes and skills that the business needs in the future. You manage those with historic attitudes and skills.',
        answerWeightings: {
          5: 2.7,
          4: 2,
          3: 1.3,
          2: 0.5,
          1: 0,
        },
      },
      {
        id: 'focussed-retention_2',
        text:
          'You shun complacency and excessively low staff turnover, instead embracing recruitment of new blood where appropriate.',
        answerWeightings: {
          5: 4.8,
          4: 3,
          3: 2,
          2: 0.8,
          1: 0,
        },
      },
    ],
    resultsText: [
      [
        'You appear to have a sound retention strategy and you manage those staff who underperform.',
        'Many managers tolerate poor performance. Firms must recruit the right staff in the first place, and to manage out those that don\'t respond well to sound leadership. Internal promotion should be used as reward for effort but "new blood" should be hired where this would enhance key competencies.',
      ],
      [
        'Staff are comfortable and feel secure. Management of underperformers could be more effective.',
        'Many managers tolerate poor performance. Firms must recruit the right staff in the first place, and to manage out those that don\'t respond well to sound leadership. Internal promotion should be used as reward for effort but "new blood" should be hired where this would enhance key competencies.',
      ],
      [
        'Performance based rewards may benefit your organisation. Your retention strategy can be honed further.',
        'Many managers tolerate poor performance. Firms must recruit the right staff in the first place, and to manage out those that don\'t respond well to sound leadership. Internal promotion should be used as reward for effort but "new blood" should be hired where this would enhance key competencies.',
      ],
      [
        'This score suggests a lack of focus. Your retention strategy should perhaps be reviewed.',
        'Many managers tolerate poor performance. Firms must recruit the right staff in the first place, and to manage out those that don\'t respond well to sound leadership. Internal promotion should be used as reward for effort but "new blood" should be hired where this would enhance key competencies.',
      ],
      [
        'You appear to be retaining staff, possible to the detriment of your organisation.',
        'Many managers tolerate poor performance. Firms must recruit the right staff in the first place, and to manage out those that don\'t respond well to sound leadership. Internal promotion should be used as reward for effort but "new blood" should be hired where this would enhance key competencies.',
      ],
    ],
  },
];
