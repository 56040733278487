export default [
  {
    id: 'enterprising_performing',
    title: 'Enterprising and Performing',
    text:
      'Focuses on results and achieving personal work objectives. Works best when work is related closely to results and the impact of personal efforts is obvious. Shows an understanding of business, commerce, and finance. Seeks opportunities for self-development and career advancement.',
  },
  {
    id: 'creating_conceptualizing',
    title: 'Creating and Conceptualizing',
    text:
      'Works well in situations requiring openness to new ideas and experiences. Seeks out learning opportunities. Handles situations and problems with innovation and creativity. Thinks broadly and strategically. Supports and drives organizational change.',
  },
  {
    id: 'analyzing_interpreting',
    title: 'Analyzing and Interpreting',
    text:
      'Shows evidence of clear analytical thinking. Gets to the heart of complex problems and issues. Applies own expertise effectively. Quickly takes on new technology. Communicates well in writing.',
  },
  {
    id: 'organizing_executing',
    title: 'Organizing and Executing',
    text:
      'Plans ahead and works in a systematic and organized way. Follows directions and procedures. Focuses on customer satisfaction and delivers a quality service or product to the agreed standards.',
  },
  {
    id: 'leading_deciding',
    title: 'Leading and Deciding',
    text:
      'Takes control and exercises leadership. Initiates action, gives direction, and takes responsibility.',
  },
  {
    id: 'supporting_cooperating',
    title: 'Supporting and Cooperating',
    text:
      'Supports others and shows respect and positive regard for them insocial situations. Puts people first, working effectively with individuals and teams, clients, and staff. Behaves consistently with clear personal values that complement those of the organization.',
  },
  {
    id: 'interacting_presenting',
    title: 'Interacting and Presenting',
    text:
      'Communicates and networks effectively. Successfully persuades and influences others. Relates to others in a confident, relaxed manner.',
  },
  {
    id: 'adapting_coping',
    title: 'Adapting and Coping',
    text:
      'Adapts and responds well to change. Manages pressure effectively and copes well with setbacks.',
  },
];
