import React from 'react';
import HRContributionAssessor from './hr-contribution-assessor';
import TrainYourEmployees from './train-your-employees';

export default (toolName, { props } = {}) => {
  switch (toolName) {
    case 'hr-contribution-assessor':
      return <HRContributionAssessor {...props} />;
    case 'train-your-employees':
      return <TrainYourEmployees {...props} />;
    default:
      return undefined;
  }
};
