import React, { useState } from 'react';
import {
  Textarea,
  Button,
  Flex,
  Tooltip,
  VStack,
  Text,
} from '@chakra-ui/react';
import Range from 'timelesstime-ui/components/range';
import Heading from 'timelesstime-ui/components/heading';

const TextAreaInput = ({ value, onChange, ...props }) => {
  const [textAreaValue, setTextAreaValue] = useState(value);
  return (
    <Textarea
      type="text"
      required={false}
      placeholder="What developmental activities are required to achieve this?"
      aria-label="What developmental activities are required to achieve this?"
      rows={3}
      value={textAreaValue}
      onChange={(event) => setTextAreaValue(event.target.value)}
      onBlur={() => onChange(textAreaValue)}
      {...props}
    />
  );
};

const Results = ({
  questions,
  answers,
  onSliderChange,
  onTextChange,
  onReset,
  onSubmit,
  levelTitles,
  levelDescriptions,
}) => (
  <>
    <VStack spacing={6} alignItems="stretch">
      {questions.map((question) => {
        const clickRangeSlider = (questionId, value) => {
          const currentValues = answers[questionId].values;
          let newValues;
          if (currentValues[1] - currentValues[0]) {
            newValues = [value, currentValues[1]];
          } else if (value > 2) {
            newValues = [currentValues[0], value];
          } else {
            newValues = [value, currentValues[1]];
          }
          onSliderChange(questionId, newValues);
        };
        return (
          <VStack key={question.title} spacing={4} alignItems="stretch">
            <Heading as="h1" size="xl" mb={0}>
              {question.title}
            </Heading>
            <Text>{question.text}</Text>
            <Range
              min={1}
              max={4}
              step={1}
              value={answers[question.id].values}
              marks={{
                1: (
                  <Tooltip
                    label={levelDescriptions[0]}
                    aria-label={levelDescriptions[0]}
                  >
                    <Button
                      variant="unstyled"
                      fontSize="sm"
                      mt={0}
                      pt={0}
                      height="auto"
                      width="auto"
                      whiteSpace={['break-word', 'nowrap']}
                      onClick={() => clickRangeSlider(question.id, 1)}
                      data-toggle="tooltip"
                    >
                      {levelTitles[0]}
                    </Button>
                  </Tooltip>
                ),
                2: (
                  <Tooltip
                    label={levelDescriptions[1]}
                    aria-label={levelDescriptions[1]}
                  >
                    <Button
                      variant="unstyled"
                      fontSize="sm"
                      mt={0}
                      pt={0}
                      height="auto"
                      width="auto"
                      whiteSpace={['break-word', 'nowrap']}
                      onClick={() => clickRangeSlider(question.id, 2)}
                      data-toggle="tooltip"
                    >
                      {levelTitles[1]}
                    </Button>
                  </Tooltip>
                ),
                3: (
                  <Tooltip
                    label={levelDescriptions[2]}
                    aria-label={levelDescriptions[2]}
                  >
                    <Button
                      variant="unstyled"
                      fontSize="sm"
                      mt={0}
                      pt={0}
                      height="auto"
                      width="auto"
                      whiteSpace={['break-word', 'nowrap']}
                      onClick={() => clickRangeSlider(question.id, 3)}
                      data-toggle="tooltip"
                    >
                      {levelTitles[2]}
                    </Button>
                  </Tooltip>
                ),
                4: (
                  <Tooltip
                    label={levelDescriptions[3]}
                    aria-label={levelDescriptions[3]}
                  >
                    <Button
                      variant="unstyled"
                      fontSize="sm"
                      mt={0}
                      pt={0}
                      height="auto"
                      width="auto"
                      whiteSpace={['break-word', 'nowrap']}
                      onClick={() => clickRangeSlider(question.id, 4)}
                      data-toggle="tooltip"
                    >
                      {levelTitles[3]}
                    </Button>
                  </Tooltip>
                ),
              }}
              onChange={(value) => onSliderChange(question.id, value)}
              handleStyle={{ cursor: 'grab' }}
            />
            <Text fontWeight="bold">{answers[question.id].summary}</Text>
            <TextAreaInput
              value={answers[question.id].text}
              onChange={(value) => onTextChange(question.id, value)}
              name={question.id}
            />
          </VStack>
        );
      })}
    </VStack>
    <Flex justify="flex-end" mt={8}>
      <Button
        size="sm"
        colorScheme="orange"
        onClick={onReset}
        aria-label="clear answers"
      >
        Reset
      </Button>
    </Flex>
    <Flex justify="center" mt={4}>
      <Button onClick={onSubmit} colorScheme="orange">
        Get my results
      </Button>
    </Flex>
  </>
);

export default Results;
