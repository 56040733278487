import React from 'react';
import { Box } from '@chakra-ui/react';
import RCSlider from 'rc-slider/es/Slider';
import 'rc-slider/assets/index.css';

const Slider = ({ boxProps, ...props }) => (
  <Box
    pb={[10, 6]} /* pb=6 account for the slider labels underneath */
    px={[8, 8, 8, 4]} /* account for the slider labels left and right */
    sx={{
      '.rc-slider-mark-text': {
        whiteSpace: ['break-spaces', 'nowrap'],
        maxW: ['80px', 'initial'],
      },
    }}
    {...boxProps}
  >
    <RCSlider {...props} />
  </Box>
);

export default Slider;
