import React, { createRef, useState } from 'react';
import mapValues from 'lodash/mapValues';
import ReactGA from 'react-ga';
import { Text, Box } from '@chakra-ui/react';

import scrollTo from 'timelesstime-ui/utils/scroll-to';
import ToolRegistration from '../tool-registration';
import QuestionsInput from './questionsInput';
import Results from './results';
import questions from './questions';
import ArtificialLoading from '../loading';

const GACategory = 'tool_train-your-employees';

const levelTitles = [
  'Trainee',
  'Supervised Practitioner',
  'Practitioner',
  'Expert',
];

const levelDescriptions = [
  'Trainee - Has basic skill, knowledge and understanding. Can work under the direct supervision of a Practitioner or Expert.',
  'Supervised Practitioner - Can work without placing an excessive burden on the Practitioner or Expert supervising their work.',
  'Practitioner - Has skill, knowledge, understanding and demonstrated experience to work without detailed supervision.',
  'Expert - Can take overall responsibility for activities. Keeps abreast of developments. Leads and develops others.',
];

const defaultAnswers = questions.reduce(
  (carry, question) => ({
    ...carry,
    [question.id]: {
      values: [1, 2],
      text: '',
    },
  }),
  {},
);

const TrainYourEmployees = () => {
  const toolWrapperRef = createRef();

  const [answers, setAnswers] = useState(defaultAnswers);
  const [showResults, setShowResults] = useState(false);

  const scrollToToolTop = scrollTo(toolWrapperRef);

  const handleSliderChange = (questionId, values) => {
    setAnswers({
      ...answers,
      [questionId]: {
        values: values.sort(),
        text: (answers[questionId] && answers[questionId].text) || '',
      },
    });
  };

  const handleTextChange = (questionId, text) => {
    setAnswers({
      ...answers,
      [questionId]: {
        text,
        values: (answers[questionId] && answers[questionId].values) || [0, 0],
      },
    });
  };

  const handleReset = () => {
    setAnswers(defaultAnswers);
    setShowResults(false);
    setTimeout(() => scrollToToolTop(), 0);
    ReactGA.event({
      category: GACategory,
      action: 'reset',
    });
  };

  const handleShowResults = () => {
    setShowResults(true);
    setTimeout(() => scrollToToolTop(), 0);
    ReactGA.event({
      category: GACategory,
      action: 'show results',
    });
  };

  const handleShowQuestions = () => {
    setShowResults(false);
    setTimeout(() => scrollToToolTop(), 0);
    ReactGA.event({
      category: GACategory,
      action: 'show questions',
    });
  };

  const answersWithSummary = mapValues(answers, (answer) => {
    const { values } = answer;
    if (values[0] === values[1]) {
      return {
        ...answer,
        summary: (
          <Text>
            Remain at <Text as="i">{levelTitles[values[0] - 1]}</Text>
          </Text>
        ),
      };
    }
    return {
      ...answer,
      summary: (
        <Text>
          Improve from <Text as="i">{levelTitles[values[0] - 1]}</Text> to{' '}
          <Text as="i">{levelTitles[values[1] - 1]}</Text>
        </Text>
      ),
    };
  });

  if (showResults) {
    const results = questions.map((question) => ({
      title: question.title,
      currentLevel: answersWithSummary[question.id].values[0],
      targetLevel: answersWithSummary[question.id].values[1],
      text: answersWithSummary[question.id].text,
      summary: answersWithSummary[question.id].summary,
    }));
    return (
      <Box ref={toolWrapperRef}>
        <ArtificialLoading
          onLoaded={() => setTimeout(() => scrollToToolTop(), 0)}
        >
          <Results
            results={results}
            onShowQuestions={() => handleShowQuestions()}
            levelTitles={levelTitles}
            levelDescriptions={levelDescriptions}
          />
        </ArtificialLoading>
      </Box>
    );
  }

  return (
    <Box ref={toolWrapperRef}>
      <ToolRegistration>
        <QuestionsInput
          questions={questions}
          answers={answersWithSummary}
          onSliderChange={(questionId, values) =>
            handleSliderChange(questionId, values)
          }
          onTextChange={(questionId, text) =>
            handleTextChange(questionId, text)
          }
          onReset={() => handleReset()}
          onSubmit={() => handleShowResults()}
          levelTitles={levelTitles}
          levelDescriptions={levelDescriptions}
        />
      </ToolRegistration>
    </Box>
  );
};

export default TrainYourEmployees;
