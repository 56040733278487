import React, { Fragment } from 'react';
import { Box, Button, Stack, Flex, Text, useToken } from '@chakra-ui/react';
import { Radar } from 'recharts/es6/polar/Radar';
import { RadarChart } from 'recharts/es6/chart/RadarChart';
import { PolarGrid } from 'recharts/es6/polar/PolarGrid';
import { PolarAngleAxis } from 'recharts/es6/polar/PolarAngleAxis';
import { PolarRadiusAxis } from 'recharts/es6/polar/PolarRadiusAxis';
import { ResponsiveContainer } from 'recharts/es6/component/ResponsiveContainer';
import { Tooltip } from 'recharts/es6/component/Tooltip';
import Heading from 'timelesstime-ui/components/heading';

const Results = ({ results, onShowQuestions, levelTitles }) => {
  const darkBlue = useToken('colors', 'tt.darkBlue');
  const lightBlue = useToken('colors', 'tt.lightBlue');
  const yellow = useToken('colors', 'yellow.500');
  const gray100 = useToken('colors', 'gray.100');
  const gray400 = useToken('colors', 'gray.400');
  const gray800 = useToken('colors', 'gray.800');
  return (
    <Box>
      <Flex justify="flex-end" mb={6}>
        <Button onClick={onShowQuestions} colorScheme="orange" size="sm">
          Go back
        </Button>
      </Flex>
      <Stack direction={['column', 'column', 'row']}>
        <Flex justify="center" flexGrow="1">
          <Box width={410} height={350}>
            <ResponsiveContainer width={410} height={350}>
              <RadarChart
                cx={200}
                cy={175}
                width={410}
                height={350}
                data={results}
              >
                <PolarGrid fill={darkBlue} stroke={gray400} />
                <PolarAngleAxis
                  dataKey="title"
                  fontSize="0.75rem"
                  fontWeight="bold"
                  tickLine={false}
                  style={{ color: gray800 }}
                />
                <PolarRadiusAxis
                  angle={60}
                  domain={[0, 4]}
                  axisLine={false}
                  tick={false}
                />
                <Radar
                  name="Your Current Level"
                  dataKey="currentLevel"
                  stroke={yellow}
                  fill={darkBlue}
                  fillOpacity={0.6}
                />
                <Radar
                  name="Your Target Level"
                  dataKey="targetLevel"
                  stroke={yellow}
                  fill={lightBlue}
                  fillOpacity={0.6}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: gray100,
                    border: `1px solid ${gray800}`,
                    padding: '4px 5px',
                  }}
                  labelStyle={{
                    color: gray800,
                    fontSize: '0.7rem',
                    fontWeight: 'bold',
                  }}
                  itemStyle={{ color: gray800, fontSize: '0.7rem' }}
                  formatter={(value) => levelTitles[value]}
                  itemSorter={(...items) => items.reverse()}
                />
              </RadarChart>
            </ResponsiveContainer>
          </Box>
        </Flex>
      </Stack>
      {results.map((answer) => (
        <Fragment key={answer.title}>
          <Heading as="h2" mb={2}>
            {answer.title}
          </Heading>
          <Text fontWeight="bold" mb={2}>
            {answer.summary}
          </Text>
          {answer.text && <Text>{answer.text}</Text>}
          {!answer.text && <Text color="gray.500">No details entered</Text>}
        </Fragment>
      ))}
    </Box>
  );
};

export default Results;
